import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button, FormGroup, Label, Row, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import { FaEye, FaSpinner, FaTrashAlt, FaPlus } from 'react-icons/fa';
import verifiedIcon from '../../images/verifiedIcon.svg';
import { fetchVisionRequestDetails, uploadVisionDocuments, addItemToInvoice, removeItemFromInvoice, fetchVisionProcedures, fetchInvoiceSummary, removeUploadedDocument } from '../../services/api/cashless-vision';
import EyeDetails from './EyeDetails';

const OrderDetailsModal = ({ isOpen, toggle, onSubmit, requestId, ModalHeader }) => {

  const [loading, setLoading] = useState(false);
  const initialized = useRef(false);

  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    email: '',
    voucherCode: '',
    walletBalance: 0,
    dependant: '',
    visionCardFile: [],
    visionCardDate: '',
    orderDate: '',
    lenskartOrderNo: '',
    shippingAddress: '',
    billingAddress: '',

    rightEye: {
      spherical: { d: '', n: '' },
      cylindrical: { d: '', n: '' },
      va: { d: '', n: '' },
      prism: { d: '', n: '' },
      base: { d: '', n: '' },
      axis: '',
      pd: ''
    },
    leftEye: {
      spherical: { d: '', n: '' },
      cylindrical: { d: '', n: '' },
      va: { d: '', n: '' },
      prism: { d: '', n: '' },
      base: { d: '', n: '' },
      axis: '',
      pd: ''
    },

    items: [
      { type: null, qty: '', totalPrice: '', discount: '', price: '', approved: '', isNew: true }
    ],
  });

  const [expandedSections, setExpandedSections] = useState({
    orderDetails: true,
    visionCardDetails: true,
    invoiceDetails: true
  });

  const visionCardInputRef = useRef(null);

  const [procedures, setProcedures] = useState([]);
  const [requestStatus, setRequestStatus] = useState('');
  const [summary, setSummary] = useState({
    items: [],
    subtotal: 0,
    paidByWallet: 0,
    coPayAmount: 0,
    walletBalance: 0
  });

  const [initialFormData, setInitialFormData] = useState(null);

  const fetchRequestDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchVisionRequestDetails(requestId);
      
      if (response.message === 'success') {
        const { requestDetails, visionCardDetails, preAuthItemDetails, walletDetails, uploadedFiles } = response;
        
        const mappedItems = preAuthItemDetails?.map(item => ({
          type: { value: item.procedureId, label: item.itemName },
          qty: item.quantity?.toString() || '', 
          totalPrice: item.totalPrice?.toString() || '',
          discount: item.discount?.toString() || '',
          price: item.cost?.toString() || '',
          approved: item.approvedAmount?.toString() || '',
          isNew: false
        })) || [];

        const visionCardFiles = uploadedFiles?.visionCard?.map((file, index) => ({
          url: file.url,
          documentId: file.documentId,
          name: `Vision Card ${index + 1}`
        })) || [];

        const invoiceFiles = uploadedFiles?.invoice?.map((file, index) => ({
          url: file.url,
          documentId: file.documentId,
          name: `Invoice ${index + 1}`
        })) || [];

        const formatDate = (dateString) => {
          if (!dateString) return '';
          const date = moment(dateString);
          return date.isValid() ? date.format('YYYY-MM-DD') : '';
        };

        setRequestStatus(requestDetails?.requestStatus || '');

        const initialData = {
          phone: requestDetails?.patientPhone || '',
          name: requestDetails?.userName || '',
          email: requestDetails?.userEmail || '',
          voucherCode: requestDetails?.voucherCode || '',
          walletBalance: walletDetails?.walletBalance || 0,
          dependant: requestDetails?.patientName || '',
          visionCardFile: visionCardFiles,
          visionCardDate: requestDetails?.visionCardDate ? formatDate(requestDetails?.visionCardDate): null,
          orderDate: requestDetails?.orderDate ? formatDate(requestDetails?.orderDate) : null,
          lenskartOrderNo: requestDetails?.lenkskartOrderId || '',
          shippingAddress: requestDetails?.lenskartShippingAddress || '',
          billingAddress: requestDetails?.lenskartBillingAddress || '',
          rightEye: {
            spherical: {
              d: visionCardDetails?.eye?.right?.distance?.far?.spherical || '',
              n: visionCardDetails?.eye?.right?.distance?.near?.spherical || '',
            },
            cylindrical: {
              d: visionCardDetails?.eye?.right?.distance?.far?.cylindrical || '',
              n: visionCardDetails?.eye?.right?.distance?.near?.cylindrical || ''
            },
            va: {
              d: visionCardDetails?.eye?.right?.distance?.far?.vA || '',
              n: visionCardDetails?.eye?.right?.distance?.near?.vA || ''
            },
            prism: {
              d: visionCardDetails?.eye?.right?.distance?.far?.prism || '',
              n: visionCardDetails?.eye?.right?.distance?.near?.prism || ''
            },
            base: {
              d: visionCardDetails?.eye?.right?.distance?.far?.base || '',
              n: visionCardDetails?.eye?.right?.distance?.near?.base || ''
            },
            axis: visionCardDetails?.eye?.right?.axis || '',
            pd: visionCardDetails?.eye?.right?.pd || '',
          },
          leftEye: {
            spherical: {
              d: visionCardDetails?.eye?.left?.distance?.far?.spherical || '',
              n: visionCardDetails?.eye?.left?.distance?.near?.spherical || '',
            },
            cylindrical: {
              d: visionCardDetails?.eye?.left?.distance?.far?.cylindrical || '',
              n: visionCardDetails?.eye?.left?.distance?.near?.cylindrical || ''
            },
            va: {
              d: visionCardDetails?.eye?.left?.distance?.far?.vA || '',
              n: visionCardDetails?.eye?.left?.distance?.near?.vA || ''
            },
            prism: {
              d: visionCardDetails?.eye?.left?.distance?.far?.prism || '',
              n: visionCardDetails?.eye?.left?.distance?.near?.prism || ''
            },
            base: {
              d: visionCardDetails?.eye?.left?.distance?.far?.base || '',
              n: visionCardDetails?.eye?.left?.distance?.near?.base || ''
            },
            axis: visionCardDetails?.eye?.left?.axis || '',
            pd: visionCardDetails?.eye?.left?.pd || '',
          },
          items: mappedItems.length > 0 ? mappedItems : [{ type: null, qty: '', totalPrice: '', discount: '', price: '', approved: '', isNew: true }],
        };

        setFormData(initialData);
        setInitialFormData(initialData);

        fetchVisionProcedures()
          .then(response => {
            if (response.message === 'success') {
              const procedureOptions = response.data.map(proc => ({
                value: proc.procedureId,
                label: proc.displayName
              }));
              setProcedures(procedureOptions);
            }
          })
          .catch(err => {
            console.error('Error fetching procedures:', err);
            toast.error('Failed to fetch procedures');
          });

        // Fetch initial summary
        const summaryResponse = await fetchInvoiceSummary(requestId);
        if (summaryResponse.message === 'success') {
          setSummary(summaryResponse.data);
        }
      }
    } catch (error) {
      toast.error('Failed to fetch request details');
    } finally {
      setLoading(false);
    }
  }, [requestId]);

  useEffect(() => {
    let isMounted = true;
    if (isOpen && requestId) {
      fetchRequestDetails().then(() => {
        if (!isMounted) return;
      });
    }
    return () => {
      isMounted = false;
    };
  }, [fetchRequestDetails, isOpen, requestId]);

  useEffect(() => {
    if (!isOpen) {
      initialized.current = false;
    }
  }, [isOpen]);

  // Check if the value is a valid number (integer or floating-point)
  const validateItemInput = (value, field) => {
    // Allow empty strings to handle input reset
    if (value === '') {
      return true;
    }
    // Check if the value is a valid number
    return !isNaN(value);
  };

  const isEditingDisabled = () => {
    return ['order-approved', 'completed', 'order-rejected', 'payment-complete'].includes(requestStatus);
  }

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.items];
    const currentItem = updatedItems[index];
  
    // For Select component (item type), directly update
    if (field === 'type') {
      updatedItems[index] = {
        ...currentItem,
        [field]: value
      };
      setFormData(prev => ({
        ...prev,
        items: updatedItems
      }));
      return;
    }
  
    // For numeric fields, validate input
    if (!validateItemInput(value, field)) {
      toast.error('Please enter a valid value');
      return;
    }
  
    // For quantity field, validate input
    if (field === 'qty' && value > 10) {
      toast.error('Quantity cannot exceed 10');
      return;
    }
  
    // Convert to number for comparison
    const numValue = parseFloat(value) || 0;
  
    // Ensure total price is entered before discount or approved amount
    if ((field === 'discount' || field === 'approved') && !currentItem.totalPrice) {
      toast.error('Please enter the total price first');
      return;
    }
  
    // Validate discount against total price
    if (field === 'discount') {
      const totalPrice = parseFloat(currentItem.totalPrice) || 0;
      if (numValue > totalPrice) {
        toast.error('Discount cannot be more than total price');
        return;
      }
    }
  
    // Validate approved amount against price
    if (field === 'approved') {
      const price = parseFloat(currentItem.price) || 0;
      if (numValue > price) {
        toast.error('Approved amount cannot be more than price');
        return;
      }
    }
  
    // Update the item
    updatedItems[index] = {
      ...currentItem,
      [field]: value
    };
  
    // Calculate price based on totalPrice and discount
    if (field === 'totalPrice' || field === 'discount') {
      const totalPrice = parseFloat(updatedItems[index].totalPrice) || 0;
      const discount = parseFloat(updatedItems[index].discount) || 0;
      updatedItems[index].price = (totalPrice - discount).toFixed(2);
    }
  
    // Calculate total approved amount
    const totalApproved = updatedItems.reduce((sum, item) => {
      return sum + (parseFloat(item.approved) || 0);
    }, 0);
  
    // Check if total approved amount exceeds wallet balance
    if (totalApproved > parseFloat(formData.walletBalance)) {
      toast.error('Total approved amount exceeds available wallet balance');
      return;
    }
  
    setFormData(prev => ({
      ...prev,
      items: updatedItems
    }));
  };

  const handleRemoveItem = async (index) => {
    const updatedItems = [...formData.items];
    const currentItem = updatedItems[index];

    // Remove item from invoice
    try {
      const removeItemResp = await removeItemFromInvoice(requestId, currentItem.type.value);
      if (removeItemResp.message === 'success') { 
        toast.success('Item removed successfully');
        // Fetch request details
        fetchRequestDetails();
        
      } else {
        toast.error('Failed to remove item');
      }
    } catch (error) {
      toast.error('Failed to remove item');
    }
  };

  const [isFormModified, setIsFormModified] = useState(false);

  const hasVisionCardDetailsChanged = (formData, initialFormData) => {
    const fields = ['spherical', 'cylindrical', 'va', 'prism', 'base'];
    const eyes = ['rightEye', 'leftEye'];
    const distances = ['d', 'n'];
  
    for (const eye of eyes) {
      for (const field of fields) {
        for (const distance of distances) {
          if (formData[eye][field][distance] !== initialFormData[eye][field][distance]) {
            return true;
          }
        }
      }
      if (formData[eye].axis !== initialFormData[eye].axis || formData[eye].pd !== initialFormData[eye].pd) {
        return true;
      }
    }
    return false;
  };
  
  const getVisionCardDetailsPayload = (formData) => ({
    eye: {
      right: {
        distance: {
          near: {
            spherical: formData.rightEye.spherical.n,
            cylindrical: formData.rightEye.cylindrical.n,
            vA: formData.rightEye.va.n,
            prism: formData.rightEye.prism.n,
            base: formData.rightEye.base.n
          },
          far: {
            spherical: formData.rightEye.spherical.d,
            cylindrical: formData.rightEye.cylindrical.d,
            vA: formData.rightEye.va.d,
            prism: formData.rightEye.prism.d,
            base: formData.rightEye.base.d
          }
        },
        pd: formData.rightEye.pd,
        axis: formData.rightEye.axis,
      },
      left: {
        distance: {
          near: {
            spherical: formData.leftEye.spherical.n,
            cylindrical: formData.leftEye.cylindrical.n,
            vA: formData.leftEye.va.n,
            prism: formData.leftEye.prism.n,
            base: formData.leftEye.base.n
          },
          far: {
            spherical: formData.leftEye.spherical.d,
            cylindrical: formData.leftEye.cylindrical.d,
            vA: formData.leftEye.va.d,
            prism: formData.leftEye.prism.d,
            base: formData.leftEye.base.d
          }
        },
        pd: formData.leftEye.pd,
        axis: formData.leftEye.axis,
      }
    }
  });
  
  const handleSubmit = async () => {
    try {
      // Validate mandatory fields
      if (!formData.lenskartOrderNo) {
        toast.error('Please enter Lenskart order number');
        return;
      }
      if (!formData.orderDate) {
        toast.error('Please select order date');
        return;
      }
      if (!formData.shippingAddress) {
        toast.error('Please enter shipping address');
        return;
      }
  
      setLoading(true);
  
      const payload = {};
  
      // Include lenskartOrderDetails only if any of its fields have changed
      if (
        formData.visionCardDate !== initialFormData.visionCardDate ||
        formData.orderDate !== initialFormData.orderDate ||
        formData.lenskartOrderNo !== initialFormData.lenskartOrderNo ||
        formData.shippingAddress !== initialFormData.shippingAddress ||
        formData.billingAddress !== initialFormData.billingAddress
      ) {
        payload.lenskartOrderDetails = {
          visionCardDate: formData.visionCardDate,
          orderDate: formData.orderDate,
          lenskartOrderId: formData.lenskartOrderNo,
          shippingAddress: formData.shippingAddress,
          billingAddress: formData.billingAddress
        };
      }
  
      // Include visionCardDetails only if any of its fields have changed
      if (hasVisionCardDetailsChanged(formData, initialFormData)) {
        payload.visionCardDetails = getVisionCardDetailsPayload(formData);
      }
  
      const response = await onSubmit(requestId, payload);
      if (response.message === 'success') { 
        toast.success('Order details submitted successfully');
        toggle();
      } else {
        // duplicate leskart order id check
        if (response?.errorMessage?.includes('Lenskart Order ID already exists')) {
          toast.error('This Lenskart Order ID already exists. Please enter a unique order ID.');
          const lenskartOrderInput = document.querySelector('input[name="lenskartOrderNo"]');
          if (lenskartOrderInput) {
            lenskartOrderInput.focus();
          }
        } else {
          toast.error(response?.errorMessage || 'Failed to submit order details');
        }
      }
    } catch (error) {
      console.log(error, 'error');
      toast.error(error.response?.data?.errorMessage || 'Failed to submit order details');
    } finally {
      setLoading(false);
    }
  };

  const validateLenskartOrderNo = (value) => {
    return /^\d*$/.test(value);
  };

  const handleInputChange = (field, value) => {
    setIsFormModified(true);
    if (field === 'lenskartOrderNo') {
      if (validateLenskartOrderNo(value)) {
        setFormData(prev => ({
          ...prev,
          [field]: value
        }));
      }
      return;
    }

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleEyeDataChange = (eye, type, field, value) => {
    setIsFormModified(true);
    setFormData(prev => ({
      ...prev,
      [eye]: {
        ...prev[eye],
        [type]: type === 'axis' || type === 'pd' ? value : {
          ...prev[eye][type],
          [field]: value
        }
      }
    }));
  };

  const handleFileUpload = (type) => {
    if (type === 'visionCard') {
      visionCardInputRef.current.click();
    }
  };

  const handleFileChange = async (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(file.type)) {
        toast.error(`Invalid file type. Only JPEG, PNG, and PDF files are allowed.`);
        return;
      }

      if (file.size > maxSize) {
        toast.error(`File too large. Maximum size is 5MB.`);
        return;
      }

      try {
        setLoading(true);
        
        // Create FormData
        const formData = new FormData();
        if (type === 'visionCard') {
          formData.append('visionCard', file);
        }

        // Call the API
        const response = await uploadVisionDocuments(requestId, formData);
        
        if (response.message === 'success') {
          fetchRequestDetails();
          
          toast.success(`Vision card uploaded successfully`);

        } else {
          toast.error('Failed to upload file');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error(error.response?.data?.message || 'Failed to upload file');
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderSummaryRow = (label, value, amount, color = '#3A2CA0', weight = '500', logo) => (
    <div key={label} style={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      marginBottom: '10px',
      color: color,
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: weight,
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {logo && (
          <>
            <span>Paid By </span>
            <img src={logo} alt="Insurer Logo" style={{ marginLeft: '8px',marginRight: '8px', height: '20px' }} />
            <span> Wallet:</span>
          </>
        )}
        {!logo && <span>{label}</span>}
      </div>
      <div style={{ display: 'flex', gap: '40px' }}>
        <span>{value}</span>
        <span style={{ minWidth: '80px', textAlign: 'right' }}>
          {amount}
        </span>
      </div>
    </div>
  );

  const handleAddItem = async (index) => {
    const newItem = formData.items[index];

    // Validate the new item
    if (!newItem.type || !newItem.qty || !newItem.totalPrice || !newItem.price || !newItem.approved) {
      toast.error('Please fill all the details for the new item before adding.');
      return;
    }

    // Add item to invoice
    try {
      const response = await addItemToInvoice(requestId, {
        visionProcedureId: newItem.type.value,
        itemName: newItem.type.label,
        cost: newItem.price,
        totalPrice: newItem.totalPrice,
        discount: newItem.discount,
        approvedAmount: newItem.approved,
        quantity: newItem.qty,
        remark: null
      });
      if (response.message === 'success') {
        toast.success('Item added successfully');
      } else if (['Procedure Is not Covered in Policy', 'Wallet deduction is not available for this procedure'].includes(response?.errorMessage)) {
        toast.error(response?.errorMessage);
      } else {
        toast.error(response?.errorMessage || 'Failed to add item');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item');
      return;
    } finally { 
      fetchRequestDetails();
    }
  };

  const handleAddNewRow = () => {
    // Check if there is already an empty row
    const hasEmptyRow = formData.items.some(item => item.isNew);
  
    if (hasEmptyRow) {
      return;
    }
  
    const newItem = {
      type: '',
      qty: '',
      price: '',
      totalPrice: '',
      discount: '',
      approved: '',
      isNew: true
    };
  
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, newItem]
    }));
  };

  const handleDateChange = (field, date) => {
    setFormData(prev => ({
      ...prev,
      [field]: date ? date.format('YYYY-MM-DD') : ''
    }));
  };

  const sectionHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #E0E0E0',
    color: '#714FFF',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Inter, sans-serif'
  };

  const renderFilePreview = (files, type) => {
    const fileArray = formData.visionCardFile;
    
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
        {Array.isArray(fileArray) && fileArray.map((file, index) => (
          <div 
            key={index}
            style={{
              backgroundColor: '#714FFF19',
              padding: '8px 12px',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <button
              onClick={() => window.open(file.url, '_blank')}
              style={{
                background: 'none',
                border: 'none',
                padding: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#714FFF'
              }}
            >
              <FaEye size={16} />
            </button>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ color: '#0F0B28' }}>
                {file.name || `${type} File ${index + 1}`}
              </span>
              <span 
                onClick={() => handleFileDelete(file.documentId, requestStatus, fileArray)}
                style={{ 
                  cursor: 'pointer',
                  color: '#666666',
                  fontSize: '18px',
                  fontWeight: '500',
                  padding: '0 4px'
                }}
              >
                ×
              </span>
            </div>
          </div>
        ))}
        <Button 
          color="primary" 
          outline
          style={{
            color: '#714FFF',
            borderColor: '#EBEAF5',
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}
          onClick={() => handleFileUpload(type)}
          disabled={isEditingDisabled()}
        >
          Upload +
        </Button>
      </div>
    );
  };

  const handleFileDelete = async (documentId, requestStatus, fileArray) => {
    try {
      if (requestStatus === 'document-uploaded' && fileArray.length === 1) { 
        toast.error('At least one file is required');
        return;
      }
      const response = await removeUploadedDocument(requestId, documentId);
      if (response.message === 'success') { 
        toast.success('File deleted successfully');
        fetchRequestDetails();
      } else {
        toast.error('Failed to delete file');
      }
    } catch (error) {
      console.log('Error deleting file:', error);
      toast.error('Failed to delete file');
    }
  };

  const renderItems = () => (
    <div style={{ backgroundColor: '#F0F3F6', borderRadius: '8px', padding: '20px' }}>
      {/* Headers */}
      <div style={{ 
        display: 'grid',
        gridTemplateColumns: '2fr 0.5fr 1.5fr 1.5fr 1.5fr 1.5fr 0.5fr',
        gap: '20px',
        marginBottom: '15px',
        color: '#0F0B28',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Inter, sans-serif'
      }}>
        <div>Item:</div>
        <div>Qty:</div>
        <div>Total Price:</div>
        <div>Discount:</div>
        <div>Price:</div>
        <div>Approved:</div>
        <div>Action:</div>
      </div>
  
      {/* Items */}
      {formData.items.map((item, index) => (
        <div key={index} style={{ 
          display: 'grid',
          gridTemplateColumns: '2fr 0.5fr 1.5fr 1.5fr 1.5fr 1.5fr 0.5fr',
          gap: '20px',
          marginBottom: '10px',
          alignItems: 'center',
          position: 'relative'
        }}>
          <Select
            value={item.type}
            onChange={(selected) => handleItemChange(index, 'type', selected)}
            options={procedures}
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: item.isNew ? '#FFFFFF' : '#F5F5F5',
                borderRadius: '8px',
                minHeight: '40px',
                border: '1px solid #E0E0E0'
              }),
              menu: (base) => ({ ...base, zIndex: 9999 })
            }}
            isDisabled={!item.isNew || isEditingDisabled()}
          />
          <Input 
            value={item.qty}
            onChange={(e) => handleItemChange(index, 'qty', e.target.value)}
            style={{
              backgroundColor: item.isNew ? '#FFFFFF' : '#F5F5F5',
              borderRadius: '8px',
              height: '40px',
              border: '1px solid #E0E0E0'
            }}
            disabled={!item.isNew || isEditingDisabled()}
          />
          <Input 
            value={item.totalPrice}
            onChange={(e) => handleItemChange(index, 'totalPrice', e.target.value)}
            style={{
              backgroundColor: item.isNew ? '#FFFFFF' : '#F5F5F5',
              borderRadius: '8px',
              height: '40px',
              border: '1px solid #E0E0E0'
            }}
            disabled={!item.isNew || isEditingDisabled()}
          />
          <Input 
            value={item.discount}
            onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
            style={{
              backgroundColor: item.isNew ? '#FFFFFF' : '#F5F5F5',
              borderRadius: '8px',
              height: '40px',
              border: '1px solid #E0E0E0'
            }}
            disabled={!item.isNew || isEditingDisabled()}
          />
          <Input 
            value={item.price}
            readOnly
            style={{
              backgroundColor: '#F5F5F5',
              borderRadius: '8px',
              height: '40px',
              border: '1px solid #E0E0E0'
            }}
          />
          <Input 
            value={item.approved}
            onChange={(e) => handleItemChange(index, 'approved', e.target.value)}
            style={{
              backgroundColor: item.isNew ? '#FFFFFF' : '#F5F5F5',
              borderRadius: '8px',
              height: '40px',
              border: '1px solid #E0E0E0'
            }}
            disabled={!item.isNew || isEditingDisabled()}
          />
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            {item.isNew ? (
              <div
                onClick={() => {
                  if (!isEditingDisabled() && requestStatus !== 'payment-pending') {
                    handleAddItem(index);
                  }
                }}
                style={{
                  background: isEditingDisabled() || requestStatus === 'payment-pending' ? '#E0E0E0' : '#4CAF50',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: isEditingDisabled() || requestStatus === 'payment-pending' ? 'not-allowed' : 'pointer',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              >
                <FaPlus style={{ color: '#FFFFFF', fontSize: '12px' }} />
              </div>
            ) : (
              <div
                onClick={() => {
                  if (!isEditingDisabled() && requestStatus !== 'payment-pending') {
                    handleRemoveItem(index);
                  }
                }}
                style={{
                  background: isEditingDisabled() || requestStatus === 'payment-pending' ? '#E0E0E0' : '#FF4D4D',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: isEditingDisabled() || requestStatus === 'payment-pending' ? 'not-allowed' : 'pointer',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              >
                <FaTrashAlt style={{ color: '#FFFFFF', fontSize: '12px' }} />
              </div>
            )}
          </div>
        </div>
      ))}
  
      {/* Add Item Button */}
      <Button
        onClick={handleAddNewRow}
        style={{
          backgroundColor: '#FFFFFF',
          color: '#714FFF',
          border: '1px solid #714FFF',
          borderRadius: '8px',
          padding: '8px 16px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          fontSize: '14px',
          fontWeight: '500',
          fontFamily: 'Inter, sans-serif',
          marginLeft: 'auto',
          marginTop: '15px'
        }}
        disabled={isEditingDisabled() || requestStatus === 'payment-pending'}
      >
        + Add Item
      </Button>
    </div>
  );

  // Add required field indicator style at the top
  const requiredField = <span style={{ color: 'red', marginLeft: '3px' }}>*</span>;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      {/* Main Header */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        borderBottom: '1px solid #E0E0E0'
      }}>
        <span style={{ 
          color: '#714FFF', 
          fontSize: '18px', 
          fontWeight: '500',
          fontFamily: 'Inter, sans-serif'
        }}>
          {ModalHeader}
        </span>
        <span 
          onClick={toggle}
          style={{ 
            cursor: 'pointer',
            fontSize: '24px',
            color: '#666666',
            fontWeight: '300'
          }}
        >
          ×
        </span>
      </div>

      {/* Create Order Section */}
      <div style={{
        padding: '20px',
        borderBottom: '1px solid #E0E0E0'
      }}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Number:
              </Label>
              <div style={{ position: 'relative' }}>
                <Input 
                  value={formData.phone}
                  readOnly
                  style={{
                    backgroundColor: '#FFFFFF',
                    cursor: 'not-allowed',
                    paddingRight: '100px' // Make space for the verified badge
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: '#86CA4B',
                    padding: '2px 8px',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    zIndex: 1
                  }}
                >
                  <img 
                    src={verifiedIcon} 
                    alt="Verified" 
                    style={{ 
                      width: '14px', 
                      height: '14px' 
                    }} 
                  />
                  <span style={{
                    color: '#FFFFFF',
                    fontSize: '12px',
                    fontWeight: '600',
                    fontFamily: 'Inter, sans-serif'
                  }}>
                    Verified
                  </span>
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Name:
              </Label>
              <Input 
                value={formData.name}
                readOnly
                style={{
                  backgroundColor: '#FFFFFF',
                  cursor: 'not-allowed'
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Email ID:
              </Label>
              <Input 
                value={formData.email}
                readOnly
                style={{
                  backgroundColor: '#FFFFFF',
                  cursor: 'not-allowed'
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Voucher Code:
              </Label>
              <Input 
                value={formData.voucherCode}
                readOnly
                style={{
                  backgroundColor: '#FFFFFF',
                  cursor: 'not-allowed'
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Dependants:
              </Label>
              <Select
                isDisabled
                value={{ 
                  label: formData.dependant, 
                  value: formData.dependant 
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: '#FFFFFF',
                    cursor: 'not-allowed'
                  })
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label style={{ 
                color: '#0F0B28', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif'
              }}>
                Wallet Balance:
              </Label>
              <div style={{
                backgroundColor: '#714FFF19',
                color: '#714FFF',
                padding: '8px 12px',
                borderRadius: '8px',
                fontWeight: '500'
              }}>
                ₹ {formData.walletBalance}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>

      {/* Order Details Section */}
      <div onClick={() => toggleSection('orderDetails')} style={sectionHeaderStyle}>
        <span>Order Details</span>
        <span style={{ 
          color: '#714FFF',
          transform: expandedSections.orderDetails ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease'
        }}>▼</span>
      </div>
      {expandedSections.orderDetails && (
        <div style={{ padding: '20px' }}>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Upload Vision Card:</Label>
              </Col>
              <Col>
                {renderFilePreview(formData.visionCardFile, 'visionCard')}
                <input
                  type="file"
                  ref={visionCardInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange('visionCard', e)}
                  disabled={isEditingDisabled()}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Vision Card Date:</Label>
              </Col>
              <Col>
                <DatePicker 
                  value={formData.visionCardDate ? moment(formData.visionCardDate) : null}
                  onChange={(date) => handleDateChange('visionCardDate', date)}
                  style={{ width: '100%' }}
                  placeholder="Select date"
                  disabled={isEditingDisabled() || formData.visionCardFile.length === 0}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Order Date{requiredField}:</Label>
              </Col>
              <Col>
                <DatePicker 
                  value={formData.orderDate ? moment(formData.orderDate) : null}
                  onChange={(date) => handleDateChange('orderDate', date)}
                  style={{ width: '100%' }}
                  placeholder="Select date"
                  disabled={isEditingDisabled()}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Lenskart Order No{requiredField}:</Label>
              </Col>
              <Col>
                <Input 
                  name="lenskartOrderNo"
                  value={formData.lenskartOrderNo}
                  onChange={(e) => handleInputChange('lenskartOrderNo', e.target.value)}
                  placeholder="Enter Lenskart order number"
                  type="text" 
                  maxLength={20}
                  style={{
                    backgroundColor: (isEditingDisabled()) ? '#F5F5F5' : '#FFFFFF',
                    borderRadius: '8px'
                  }}
                  disabled={isEditingDisabled()}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Shipping Address{requiredField}:</Label>
              </Col>
              <Col>
                <Input 
                  type="textarea"
                  value={formData.shippingAddress}
                  onChange={(e) => handleInputChange('shippingAddress', e.target.value)}
                  placeholder="Enter shipping address"
                  style={{
                    backgroundColor: (isEditingDisabled()) ? '#F5F5F5' : '#FFFFFF',
                    borderRadius: '8px'
                  }}
                  disabled={isEditingDisabled()}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label style={{ fontFamily: 'Inter, sans-serif' }}>Billing Address:</Label>
              </Col>
              <Col>
                <Input 
                  type="textarea"
                  value={formData.billingAddress}
                  placeholder="Enter billing address"
                  onChange={(e) => handleInputChange('billingAddress', e.target.value)}
                  style={{
                    backgroundColor: isEditingDisabled() ? '#F5F5F5' : '#FFFFFF',
                    borderRadius: '8px'
                  }}
                  disabled={isEditingDisabled()}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      )}

      {/* Vision Card Details Section */}
      <div onClick={() => toggleSection('visionCardDetails')} style={sectionHeaderStyle}>
        <span>Vision Card Details</span>
        <span style={{ 
          color: '#714FFF',
          transform: expandedSections.visionCardDetails ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease'
        }}>▼</span>
      </div>
      {expandedSections.visionCardDetails && (
        <div>
          <div style={{ padding: '20px' }}>
            {/* Right Eye */}
            <div style={{ marginBottom: '20px' }}>
              <EyeDetails 
                eye="Right"
                formData={formData}
                handleEyeDataChange={handleEyeDataChange}
                isEditingDisabled={isEditingDisabled}
              />
            </div>

            {/* Left Eye */}
            <div>
              <EyeDetails 
                eye="Left"
                formData={formData}
                handleEyeDataChange={handleEyeDataChange}
                isEditingDisabled={isEditingDisabled}
              />
            </div>
          </div>
        </div>
      )}

      {/* Invoice Details Section */}
      <div onClick={() => toggleSection('invoiceDetails')} style={sectionHeaderStyle}>
        <span>Invoice Details</span>
        <span style={{ 
          color: '#714FFF',
          transform: expandedSections.invoiceDetails ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease'
        }}>▼</span>
      </div>
      {expandedSections.invoiceDetails && (
        <div style={{ padding: '20px' }}>
          <Label style={{ fontFamily: 'Inter, sans-serif' }}>
            Invoice Items{requiredField}
          </Label>
          {renderItems()}

          {/* Order Summary */}
          <div style={{
            backgroundColor: '#F2F0FF',
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px'
          }}>
            <div style={{ color: '#0F0B28', marginBottom: '10px', font: 'Inter', fontsize: '14px', fontWeight: '500', lineHeight: '16.8px', textAlign: 'left'}}>
              Order Summary:
            </div>
            
            {summary.items.map((item, index) => (
              renderSummaryRow(
                `${item.itemName} :`,
                item.quantity ? `${item.quantity} unit` : '-',
                `₹ ${item.cost}`
              )
            ))}

            <div style={{ borderTop: '1px solid #E0E0E0', margin: '10px 0' }} />
            
            {renderSummaryRow('Subtotal :', '', `₹ ${summary.totalAmount}`)}
            {renderSummaryRow(
              'Paid By Wallet:',
              '',
              `-₹ ${summary.totalWalletDeduction}`,
              '#3A2CA0',
              '500',
              summary.insurerLogo
            )}
            {renderSummaryRow('Co-Pay Amount :', '', `₹ ${summary.totalCopay}`, '#FF754C', '500')}
            {renderSummaryRow('Current Wallet Balance :', '', `₹ ${formData.walletBalance}`, '#3A2CA0', '700')}
          </div>
        </div>
      )}

      {/* Bottom Message */}
      <div style={{
        backgroundColor: '#E8F8EF',
        margin: '20px',
        padding: '12px 16px',
        borderRadius: '8px',
        textAlign: 'center',
        color: '#585969',
        fontSize: '14px'
      }}>
        Tap on the button below to deduct the amount from user's wallet and trigger a WhatsApp message to complete the payment
      </div>

      {/* Action Button */}
      <div style={{ padding: '20px' }}>
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '100%',
            borderColor: 'rgb(113, 79, 255)',
            color: 'white',
            height: '44px',
            fontWeight: '600',
            borderRadius: '8px',
            fontFamily: 'Inter, sans-serif'
          }}
          onClick={handleSubmit}
          disabled={loading || isEditingDisabled() || !isFormModified}
        >
          {loading ? <FaSpinner className="icon-spin" /> : 'Submit'}
        </Button>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;